
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { gradientColours, getFormattedTimeInfo } from '@/services/helpers';
import { Screen } from '@/types/index.d';

import Card from '@/components/Card.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import { deleteScreen } from '@/services/axios';

@Component({
  components: {
    Card,
    DetailPanel,
    SectionHeading,
    SectionH1,
    SectionH2,
  },
})
export default class ViewScreen extends Vue {
  @Prop() readonly selectedScreen!: Screen;

  @Action loadScreens!: () => any;
  @State devices!: any[];
  @State layouts!: any[];
  @State opId!: number;

  get devicesUsingCurrentScreen(): any[] {
    const scrn_id = this.selectedScreen.scrn_id;
    return this.devices.filter(dv => dv.scrn_id === scrn_id);
  }

  get gradientColours(): string[] {
    return gradientColours;
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  deleteScreen(): void {
    if (!this.selectedScreen) return;

    if (window.confirm('คุณต้องการลบหน้าจอนี้หรือไม่?')) {
      deleteScreen(this.selectedScreen.op_id, this.selectedScreen.scrn_id)
        .then(this.loadScreens)
        .then(() => this.$emit('screen-deleted'));
    }
  }

  editScreen(): void {
    this.$router.push({
      name: 'screens-edit-view',
      params: {
        screenId: `${this.selectedScreen.scrn_id}`,
      },
    });
  }

  openDeviceScreenLinkModal(): void {
    this.$emit('open-device-screen-link-modal');
  }
}
