
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { Device, Layout } from '@/types/index.d';

import AddLayoutModal from '@/components/screens/AddLayoutModal.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import { gradientColours, getFormattedTimeInfo } from '@/services/helpers';
import { putScreen } from '@/services/axios';

@Component({
  components: {
    AddLayoutModal,
    DetailPanel,
    SectionHeading,
    SectionH1,
    SectionH2,
  },
})
export default class EditScreen extends Vue {
  @Action loadScreens!: () => any;
  @State layouts!: any[];
  @State opId!: number;
  @State screens!: any[];

  @Watch('layouts', { deep: true })
  onLayoutsChange() {
    this.prepareLayoutsScreens();
  }

  @Watch('screens', { deep: true })
  onScreensChange() {
    this.prepareLayoutsScreens();
  }

  screenSchedules: any[] = [];
  screenName: string = '';
  selectedLayouts: any[] = [];

  isShowingAddLayoutModal: boolean = false;

  get editingScreenId(): number {
    return parseInt(this.$route.params.screenId, 10);
  }

  get gradientColours(): string[] {
    return gradientColours;
  }

  addLayout([layout, preparedLayout]: [Layout, any]): void {
    this.selectedLayouts.push(layout);
    this.screenSchedules.push(preparedLayout);
  }

  closeAddLayoutModal(): void {
    this.isShowingAddLayoutModal = false;
  }

  deselectMedia(index: number): void {
    this.selectedLayouts = this.selectedLayouts.filter((l, i) => i !== index);
    this.screenSchedules = this.screenSchedules.filter((l, i) => i !== index);
  }

  move(index: number, diff: number): void {
    const temp = this.selectedLayouts[index];
    this.$set(this.selectedLayouts, index, this.selectedLayouts[index + diff]);
    this.$set(this.selectedLayouts, index + diff, temp);

    const scheduleTemp = this.screenSchedules[index];
    this.$set(this.screenSchedules, index, this.screenSchedules[index + diff]);
    this.$set(this.screenSchedules, index + diff, scheduleTemp);
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  openAddLayoutModal(): void {
    this.isShowingAddLayoutModal = true;
  }

  prepareLayoutsScreens(): void {
    if (!this.layouts.length || !this.screens.length) return;

    const screen = this.screens.find(s => s.scrn_id === this.editingScreenId);
    if (!screen) return;

    this.screenName = screen.name;

    for (const item of screen.schedules) {
      const layout = this.layouts.find(l => l.lyt_id === item.lyt_id);
      const preparedLayout = {
        lyt_id: item.lyt_id,
        loc_id: 2,
        start_at: `${item.start_at}Z`,
        end_at: `${item.end_at}Z`,
        time_flag: 1,
        remark_flag: 0,
        time_sun: item.time_sun,
        time_mon: item.time_mon,
        time_tue: item.time_tue,
        time_wed: item.time_wed,
        time_thu: item.time_thu,
        time_fri: item.time_fri,
        time_sat: item.time_sat,
        time_remark: item.time_remark,
      };

      this.addLayout([layout, preparedLayout]);
    }
  }

  updateScreen(): void {
    const preparedScreen = {
      name: this.screenName,
      is_act: true,
      schedules: this.screenSchedules,
    };

    putScreen(this.opId, this.editingScreenId, preparedScreen)
      .then(this.loadScreens)
      .then(() => this.$router.push({ name: 'screens-view' }));
  }

  mounted() {
    if (this.layouts.length && this.screens.length) this.prepareLayoutsScreens();
  }
}
