var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedScreen)?_c('DetailPanel',{staticClass:"padded-detail-panel"},[_c('SectionHeading',{attrs:{"padding":"0"}},[_c('SectionH1',[_vm._v(_vm._s(_vm.selectedScreen.name))]),_c('div',[_c('RoundButton',{staticClass:"icon-button bg-light-blue text-blue",nativeOn:{"click":function($event){return _vm.editScreen.apply(null, arguments)}}},[_c('img',{staticClass:"small-icon",attrs:{"src":require("@/assets/icons/ic-edit.svg")}}),_c('div',[_vm._v("แก้ไข")])]),_c('RoundButton',{staticClass:"icon-button margin-left bg-light-red text-red",nativeOn:{"click":function($event){return _vm.deleteScreen.apply(null, arguments)}}},[_c('img',{staticClass:"small-icon",attrs:{"src":require("@/assets/icons/ic-delete.svg")}}),_c('div',[_vm._v("ลบ")])])],1)],1),_c('SectionH2',{staticClass:"margin-top"},[_vm._v("รูปแบบที่ตั้งไว้")]),_c('section',{attrs:{"id":"selected-screen__detail-container"}},[_c('div',{staticStyle:{"border-radius":"4px","border":"solid 1px #dbe0f0","width":"67rem"},attrs:{"id":"selected-layout"}},_vm._l((_vm.selectedScreen.schedules),function(layout){return _c('div',{key:`${layout.pos}-${layout.lyt_id}`,staticClass:"layout__container"},[(layout.slots)?_c('div',{staticClass:"layout__template-slot-container"},_vm._l((layout.slots),function(block,i){return _c('div',{key:block.ct_slot_no,staticClass:"layout__template-slot",style:({
              backgroundColor: _vm.gradientColours[i],
              height: `${block.pcnt_height * 100}%`,
              left: `${block.pcnt_x * 100}%`,
              top: `${block.pcnt_y * 100}%`,
              width: `${block.pcnt_width * 100}%`,
              zIndex: block.z_idx,
            })},[_vm._v(" "+_vm._s(block.ct_slot_no)+" ")])}),0):_vm._e(),_c('div',{staticClass:"layout__info-container"},[_c('strong',{staticClass:"layout__name"},[_vm._v(_vm._s(layout.name))]),_c('div',{staticClass:"layout__schedule"},[_vm._v(" "+_vm._s(_vm.getFormattedTimeInfo( new Date(`${layout.start_at}Z`), new Date(`${layout.end_at}Z`), layout.time_sun ))+" ")])])])}),0),_c('div',{staticStyle:{"width":"24rem","margin-left":"1rem"},attrs:{"id":"applied-devices"}},[_c('Card',{staticStyle:{"background":"#edeff7","box-shadow":"none"}},[_c('div',{staticStyle:{"font-size":"2rem","font-weight":"600","color":"#7a88b8"}},[_vm._v("ใช้ในอุปกรณ์")]),_vm._l((_vm.devicesUsingCurrentScreen),function(device){return _c('div',{key:device.usr_id,staticStyle:{"font-size":"2rem","font-weight":"600","color":"#263373","margin-top":"1rem"}},[_vm._v(" "+_vm._s(device.name || `อุปกรณ์ไม่มีชื่อ ${device.usr_id}`)+" ")])})],2)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }