
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Layout, Screen } from '@/types/index.d';

import Card from '@/components/Card.vue';
import CreateScreen from '@/components/screens/CreateScreen.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import DeviceScreenLink from '@/components/screens/DeviceScreenLink.vue';
import EditScreen from '@/components/screens/EditScreen.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';
import ViewScreen from '@/components/screens/ViewScreen.vue';

@Component({
  components: {
    Card,
    CreateScreen,
    DetailPanel,
    DeviceScreenLink,
    EditScreen,
    SectionH1,
    SectionH2,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
    ViewScreen,
  },
})
export default class ScreensView extends Vue {
  @State layouts!: Layout[];
  @State screens!: Screen[];

  isCreatingNewScreen: boolean = false;
  isShowingDeviceScreenLinkModal: boolean = false;
  selectedScreen: Screen | null = null;

  get mappedScreens(): any[] {
    return this.screens.map(s => ({
      ...s,
      schedules: s.schedules.map(schd => ({
        ...schd,
        ...this.layouts.find(l => l.op_id === s.op_id && l.lyt_id === schd.lyt_id),
      })),
    }));
  }

  closeDeviceScreenLinkModal(): void {
    this.isShowingDeviceScreenLinkModal = false;
  }

  openCreateScreenPanel(): void {
    this.selectedScreen = null;
    this.$router.push({
      name: 'screens-create-view',
    });
  }

  openDeviceScreenLinkModal(): void {
    this.isShowingDeviceScreenLinkModal = true;
  }
  screenCreated(): void {
    this.selectedScreen = null;
  }

  screenDeleted(): void {
    this.selectedScreen = null;
  }

  selectScreen(screen: Screen): void {
    this.selectedScreen = screen;
    this.$router.push({
      name: 'screens-view-view',
      params: {
        screenId: `${screen.scrn_id}`,
      },
    });
  }
}
