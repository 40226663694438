var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DetailPanel',{staticClass:"padded-detail-panel"},[_c('SectionHeading',{attrs:{"padding":"0"}},[_c('SectionH1',[_vm._v("สร้างหน้าจอใหม่")]),_c('RoundButton',{staticClass:"icon-button bg-blue text-white",nativeOn:{"click":function($event){return _vm.createScreen.apply(null, arguments)}}},[_c('div',[_vm._v("บันทึก")])])],1),_c('section',{staticClass:"margin_top-40",attrs:{"id":"new-screen"}},[_c('label',{staticClass:"block"},[_c('span',{staticClass:"text-pale-blue text-16 text-600"},[_vm._v("ชื่อ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.screenName),expression:"screenName"}],staticClass:"block",attrs:{"type":"text"},domProps:{"value":(_vm.screenName)},on:{"input":function($event){if($event.target.composing)return;_vm.screenName=$event.target.value}}})]),_c('div',{staticClass:"margin_top-16"},[_c('div',{staticClass:"text-pale-blue text-16 text-600"},[_vm._v("เลย์เอาต์")]),_c('div',{staticClass:"text-pale-blue text-16"},[_vm._v("ระบบจะเล่นตามลำดับความสำคัญนี้")])]),_c('div',{staticClass:"margin_top-16",attrs:{"id":"create-screen-layout-list-container"}},[_vm._l((_vm.selectedLayouts),function(layout,i){return _c('div',{key:`${layout.lyt_id}-${i}`,staticStyle:{"align-items":"center","display":"flex","padding":"2rem"}},[(layout.slots)?_c('div',{staticClass:"layout__template-slot-container",staticStyle:{"height":"10rem","width":"18rem"}},_vm._l((layout.slots),function(slot,i){return _c('div',{key:slot.st_slot_no,staticClass:"layout__template-slot",style:({
              backgroundColor: _vm.gradientColours[i],
              color: 'white',
              height: `${slot.pcnt_height * 100}%`,
              left: `${slot.pcnt_x * 100}%`,
              top: `${slot.pcnt_y * 100}%`,
              width: `${slot.pcnt_width * 100}%`,
              zIndex: slot.z_idx,
            })},[_vm._v(" "+_vm._s(slot.ct_slot_no)+" ")])}),0):_vm._e(),_c('div',{staticStyle:{"display":"block"}},[_c('div',{staticClass:"text-16 text-600 text-dark-blue"},[_vm._v(" "+_vm._s(layout.name)+" ")]),_c('div',{staticClass:"text-pale-blue text-16 text-600"},[_vm._v(" "+_vm._s(_vm.getFormattedTimeInfo( new Date(`${_vm.screenSchedules[i].start_at}`), new Date(`${_vm.screenSchedules[i].end_at}`), _vm.screenSchedules[i].time_sun ))+" ")])]),_c('div',{attrs:{"id":"create-screen-layout-list__arrows"}},[_c('img',{class:{ hide: i === 0 },attrs:{"src":require("@/assets/icons/ic-arrow-n.svg")},on:{"click":function($event){return _vm.move(i, -1)}}}),_c('img',{class:{ hide: i === _vm.selectedLayouts.length - 1 },attrs:{"src":require("@/assets/icons/ic-arrow-s.svg")},on:{"click":function($event){return _vm.move(i, 1)}}})]),_c('div',[_c('img',{staticClass:"hover_pointer",attrs:{"src":require("@/assets/icons/ic-close-circle.svg")},on:{"click":function($event){return _vm.deselectMedia(i)}}})])])}),_c('div',{staticClass:"hover_pointer",staticStyle:{"align-items":"center","background":"#dbe0f0","display":"flex","padding":"2rem"},on:{"click":_vm.openAddLayoutModal}},[_c('div',{staticClass:"layout-preview",staticStyle:{"align-items":"center","background":"#99aaff","color":"#fff","display":"flex","font-size":"3rem","height":"10rem","justify-content":"center","width":"18rem"}},[_vm._v(" + ")]),_c('div',{staticClass:"text-16 text-600 text-blue",staticStyle:{"margin-left":"2rem"}},[_vm._v(" เพิ่มเลย์เอาต์ใหม่ ")])])],2)]),(_vm.isShowingAddLayoutModal)?_c('AddLayoutModal',{on:{"close-modal":_vm.closeAddLayoutModal,"layout-added":_vm.addLayout}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }