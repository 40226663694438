
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import { Device, Layout } from '@/types/index.d';

import AddLayoutModal from '@/components/screens/AddLayoutModal.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SectionH2 from '@/components/heading/SectionH2.vue';
import { gradientColours, getFormattedTimeInfo } from '@/services/helpers';
import { postScreen } from '@/services/axios';

@Component({
  components: {
    AddLayoutModal,
    DetailPanel,
    SectionHeading,
    SectionH1,
    SectionH2,
  },
})
export default class CreateScreen extends Vue {
  @Action loadScreens!: () => any;
  @State opId!: number;

  screenSchedules: any[] = [];
  screenName: string = '';
  selectedLayouts: any[] = [];

  isShowingAddLayoutModal: boolean = false;

  get gradientColours(): string[] {
    return gradientColours;
  }

  addLayout([layout, preparedLayout]: [Layout, any]): void {
    this.selectedLayouts.push(layout);
    this.screenSchedules.push(preparedLayout);
  }

  closeAddLayoutModal(): void {
    this.isShowingAddLayoutModal = false;
  }

  createScreen(): void {
    const preparedScreen = {
      name: this.screenName,
      is_act: true,
      schedules: this.screenSchedules,
    };

    postScreen(this.opId, preparedScreen)
      .then(this.loadScreens)
      .then(() => this.$emit('screen-created'))
      .then(() => this.$router.push({ name: 'screens-view' }));
  }

  deselectMedia(index: number): void {
    this.selectedLayouts = this.selectedLayouts.filter((l, i) => i !== index);
  }

  move(index: number, diff: number): void {
    const temp = this.selectedLayouts[index];
    this.$set(this.selectedLayouts, index, this.selectedLayouts[index + diff]);
    this.$set(this.selectedLayouts, index + diff, temp);
  }

  getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
    return getFormattedTimeInfo(startAt, endAt, timeSun);
  }

  openAddLayoutModal(): void {
    this.isShowingAddLayoutModal = true;
  }
}
