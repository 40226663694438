
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

import { linkDeviceWithScreen } from '@/services/axios';
import { Device } from '@/types/index.d';

import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

@Component({
  components: {
    Modal,
    SectionH1,
  },
})
export default class DeviceNodeVehicleLinkView extends Vue {
  @Prop(Number) readonly screenId!: number;

  @Action loadDevices!: () => any;
  @State devices!: any[];
  @State opId!: number;

  selectedDevices: any[] = [];

  mounted() {
    this.selectedDevices = this.devicesUsingCurrentScreen.map(dv => dv.usr_id);
  }

  get devicesUsingCurrentScreen(): any[] {
    const scrn_id = this.screenId;
    return this.devices.filter(dv => dv.scrn_id === scrn_id);
  }

  closeModal(): void {
    this.$emit('close-modal');
  }

  linkVehicles(): void {
    const preparedDeviceScreenLink = {
      cfg: this.selectedDevices.map(dv => ({
        usr_id: dv,
        scrn_id: this.screenId,
        is_act: true,
      })),
    };

    linkDeviceWithScreen(this.opId, preparedDeviceScreenLink)
      .then(this.loadDevices)
      .then(this.closeModal);
  }

  selectAll(): void {
    this.selectedDevices = this.devices.map(d => d.usr_id);
  }

  selectNone(): void {
    this.selectedDevices = [];
  }
}
