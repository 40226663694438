
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Playlist, Layout } from '@/types/index.d';
import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

type ContentAction = 'create' | 'update';
type ContentType = 'playlist' | 'website';

@Component({
  components: {
    Modal,
    SectionH1,
  },
  filters: {
    twoDigits(val: number | string): string {
      return `${val}`.padStart(2, '0');
    },
  },
})
export default class PlaylistCRUD extends Vue {
  @Prop(String) readonly contentAction!: ContentAction;
  @Prop(String) readonly contentType!: ContentType;

  @State layouts!: Layout[];

  endDate: Date = new Date();
  endShowingTimeHH: number = 0;
  endShowingTimeMM: number = 0;
  playlistPlaybackType: number = 0;
  selectedLayoutIndex: number = 0;
  showingDateType: number = 0;
  showingTimeType: number = 0;
  startDate: Date = new Date();
  startShowingTimeHH: number = 0;
  startShowingTimeMM: number = 0;

  get playlistPlaybackOptions(): string[] {
    return ['ตามลำดับ', 'สุ่ม'];
  }

  get showingOptions(): string[] {
    return ['ตลอดเวลา', 'กำหนดเอง'];
  }

  addLayout(): void {
    let startAt = '';
    let endAt = '';

    if (this.showingDateType === 0) {
      // Let's hope this will not be used until the end of the century! 🤞🤞🤞
      startAt = '2000-01-01';
      endAt = '2099-12-31';
    } else {
      this.startDate.setHours(this.startDate.getHours() + 7);
      this.endDate.setHours(this.endDate.getHours() + 7);

      startAt = this.startDate.toISOString().split('T')[0];
      endAt = this.endDate.toISOString().split('T')[0];
    }

    let startTimeSeconds = 0;
    let endTimeSeconds = 86399;

    if (this.showingTimeType === 0) {
      startAt += 'T00:00:00+07:00';
      endAt += 'T23:59:59+07:00';
    } else {
      startAt += `T${this.startShowingTimeHH
        .toString()
        .padStart(2, '0')}:${this.startShowingTimeMM.toString().padStart(2, '0')}:00+07:00`;
      endAt += `T${this.endShowingTimeHH
        .toString()
        .padStart(2, '0')}:${this.endShowingTimeMM.toString().padStart(2, '0')}:00+07:00`;

      startTimeSeconds = this.startShowingTimeHH * 60 * 60 + this.startShowingTimeMM * 60;
      endTimeSeconds = this.endShowingTimeHH * 60 * 60 + this.endShowingTimeMM * 60;
    }

    const preparedLayout = {
      lyt_id: this.layouts[this.selectedLayoutIndex].lyt_id,
      loc_id: 2,
      start_at: new Date(startAt).toISOString(),
      end_at: new Date(endAt).toISOString(),
      time_flag: 1,
      remark_flag: 0,
      time_sun: [[startTimeSeconds, endTimeSeconds]],
      time_mon: [],
      time_tue: [],
      time_wed: [],
      time_thu: [],
      time_fri: [],
      time_sat: [],
      time_remark: [],
    };

    this.$emit('layout-added', [this.layouts[this.selectedLayoutIndex], preparedLayout]);
    this.closeModal();
  }

  closeModal(): void {
    this.$emit('close-modal');
  }
}
